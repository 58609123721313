import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB v3 Sitemaps for your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/",
  "dateChanged": "2022-08-17",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version 3 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB v3 Sitemaps for your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version 7 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "openhab-v3-sitemaps-for-your-wqhd-instar-mqttv5-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-v3-sitemaps-for-your-wqhd-instar-mqttv5-broker",
        "aria-label": "openhab v3 sitemaps for your wqhd instar mqttv5 broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB v3 Sitemaps for your WQHD INSTAR MQTTv5 Broker`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/"
        }}>{`Part I - MQTT Binding Configuration through the Main UI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/"
        }}>{`Part II - MQTT Binding Configuration through the Configuration Files`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/"
        }}>{`Part III - MQTT Binding Rules`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/"
        }}>{`Part IV - MQTT Binding Scripts`}</a></li>
      <li parentName="ul">{`Part V - Groups and Sitemaps`}</li>
    </ul>
    <h2 {...{
      "id": "grouping-your-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#grouping-your-items",
        "aria-label": "grouping your items permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Grouping your Items`}</h2>
    <p><strong parentName="p">{`A`}</strong>{`: Part 1 of this tutorial installed the OpenHAB MQTT binding and connected it to the internal broker in our INSTAR WQHD camera using the OpenHab 3 `}<strong parentName="p">{`Main UI`}</strong>{`. The `}<strong parentName="p">{`Part II`}</strong>{` did the same by editing the configuration files of our OpenHab 3 installation. In `}<strong parentName="p">{`Part III`}</strong>{` we added some automations using the OpenHab `}<strong parentName="p">{`Rules`}</strong>{` feature. And now it is time to build a dashboard by grouping items and building a sitemap.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#grouping-your-items"
        }}>{`Grouping your Items`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sitemaps"
        }}>{`Sitemaps`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`To goup your `}<strong parentName="p">{`Items`}</strong>{` create a file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`groups.items`}</code>{` in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./items`}</code>{` directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Location`}</span>{`
Group office `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Office"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Floors`}</span>{`
Group basement `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Basement"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`office`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group ground_floor `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Ground Floor"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`office`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group first_floor `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"First Floor"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`office`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group front_entrance `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Front Entrance"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`office`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Rooms`}</span>{`
Group reception `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Reception"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ground_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group hallways `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Hallways"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ground_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`first_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group offices `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Offices"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ground_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`first_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group storage `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Storage"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`basement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`first_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group outdoor `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Outdoor"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`front_entrance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Sorted by Function`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Cameras`}</span>{`
Group cameras `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Cameras"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`office`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group cam_basement `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Basement Cameras"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`basement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group cam_ground `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Ground Floor Cameras"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`ground_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group cam_first `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"First Floor Cameras"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`first_floor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group cam_entrance `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Entrance Cameras"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`front_entrance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Sorted by Binding`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// MQTT`}</span>{`
Group mqtt `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"MQTT"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`office`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Group mqtt_cameras `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"MQTT Cameras"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`We can also pre-define `}<strong parentName="p">{`Item Types`}</strong>{` that we want to use in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./items`}</code>{` directory inside a file named `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`itemtypes.items`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`Group   Cam_group   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Group"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`group`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`office`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Switch   PrivacyAreas   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Switch [MAP(INSTAR.map):%s]"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`          
Dimmer   Cam_Dimmer   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Dimmer [%.0f %%]"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`chart`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`   
Color   Cam_Color   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Color [%s]"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`colorpicker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Number   Cam_Number   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Number [%.2f °C]"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`temperature`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
String   Cam_String   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type String"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`error`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Image   Cam_Image   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Image"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`camera`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
Location   Cam_Location   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Location"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`presence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now we can assign a group to our items:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Switch  PrivacyAreas `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"All Privacy Areas"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`camera`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reception,cam_entrance,mqtt_cameras`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`channel`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt:topic:home:PrivacyArea1, mqtt:topic:home:PrivacyArea2,mqtt:topic:home:PrivacyArea3,mqtt:topic:home:PrivacyArea4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And your `}<strong parentName="p">{`Item`}</strong>{` will now be part of the assigned groups:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/f53a0/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABdklEQVQoz2XLPW/bMBAGYO7pYFkUP8wP3VFkQko2bcsR4sJNAmQ3vBTt1mb1L+hcoEOH/B//vcJp4Nrpg3c43N1LEDHnHGPs+x4RnTUOEV4hotZ6tVrt9/u2ba21eDoBNADEGBNjTCnNZjNrrTbm9XTknOOc73a7w+Gw2WyklKcyANQAJKUUYxRCMsaUUngGALz3wzBst9u+751zeIn0q9v7h4ec5z4E74MQgl9ijFFK+X+ElCQGv8zTG+8asGhNOS4Z4+c5/gnxbskqVlJK6i8/w/cX/vkX//qbb39QJuxRbW1tjFVKh3D97fnZGHserQ2tKtLM13F4xNkd5HXd9iWljLGqYoxxISTnwmj99GkjjuQpnAtKK9LFm9yl4MBjjbWmlGqtjDFKqdFoVBTFuBgVH67GxXtlScli2XfTadt1PoQacDKZrNcfc54jOqX032hTn+a3jTYlrcgwDIvFsuu6pmkAgHOeUhvCtZSTf6+XzbdyWf0BVopPSF9FzuUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/23a38/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/dac18/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/f53a0/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/196f6c65e5d86c3dec25d2ad7dea4e2d/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`Group Items`}</strong>{` will also be shown in the `}<strong parentName="p">{`Model`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e906c47af6403ec19d0e2c7d83879485/ee9b6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACT0lEQVQoz42SzW7TQBSF/QakSTyTmbn3zv/YsRPHdv6ahFYIiaJK7CoheAFgyTsgwYKXRk4QKrQLjs7208w952TW2hhjCL6uagBYJV+n4GOIZ3nv27Y9HU9938cY09/KUkpt2/Z9v9/vEMH5UJZlDAN2kXOOzjLGam0Q0Rh7cWZdAKTJlE1zzoXU2nRdX1c1AkqppFRKQZ7njHEhJCIdDgchpBCSMZ4dN+3tYVsnm4xypEKIKRUA+MeIxBiTUgJgSsWnz18QiUhLKbP65t327sHv3uj+tWmORLooSuf8Y5hzPhqNXoxGk6srnF5NJ5PxZDIejzP88MN8/M4evvEPP8X9VyRdPoHzPK+qKsVERErK6XTKOc9zli0KvyyjJxm0sqQQqSjmznki/Rguy/nxdNO2nQ8Rkax1AJCV5byqau+D98Fah6RTKkNMxlilEIAQNWecCyFAc8ZmM3EJjHOezefzuq4vlVprUakiJWcMAoBSoCSCYvnU+ejLJRNqxn8rz/OsaVbr9XqxWAyFxgKKXte72B7D6mDqLZQ9VlsmCUmHqpEwJM+HTiVjLKuqarlcxpQGOERMS7/YuKrXxYpSA3GBRcPkcPpms+67TikllFjdr5XFzJ11GZO1FqSk4VCA80RASlSKs1wI0XX98Xgqi9I619ytyenMP9I5Q1KAChCQBl8CG/KSu93+9vY2xWSc3b+/0dE+hfEfX0YihOza/vr6EEIkY5q3a3zu5WdhJqTabHen08siDd9evurJ/ifM2EyRsoWUw4oQUORixme/ADEvgYiQnko1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e906c47af6403ec19d0e2c7d83879485/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 230w", "/en/static/e906c47af6403ec19d0e2c7d83879485/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 460w", "/en/static/e906c47af6403ec19d0e2c7d83879485/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 920w", "/en/static/e906c47af6403ec19d0e2c7d83879485/d45c9/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 1041w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e906c47af6403ec19d0e2c7d83879485/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 230w", "/en/static/e906c47af6403ec19d0e2c7d83879485/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 460w", "/en/static/e906c47af6403ec19d0e2c7d83879485/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 920w", "/en/static/e906c47af6403ec19d0e2c7d83879485/e0a35/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 1041w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e906c47af6403ec19d0e2c7d83879485/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 230w", "/en/static/e906c47af6403ec19d0e2c7d83879485/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 460w", "/en/static/e906c47af6403ec19d0e2c7d83879485/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 920w", "/en/static/e906c47af6403ec19d0e2c7d83879485/ee9b6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 1041w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e906c47af6403ec19d0e2c7d83879485/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "sitemaps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sitemaps",
        "aria-label": "sitemaps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sitemaps`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.openhab.org/docs/ui/sitemaps.html"
      }}>{`Sitemaps`}</a>{` are one way to select and compose these elements into a user-oriented representation for various User Interfaces (UIs), including the openHAB app for Android. Sitemaps are text files with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.sitemap`}</code>{` extension, and are stored in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$OPENHAB_CONF/sitemaps`}</code>{` directory:`}</p>
    <p><em parentName="p">{`sitemaps/INSTAR.sitemap`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`sitemap cameras label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Camera Surveillance"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    Frame label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Camera 118"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        Default item`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Home label`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Home"`}</span>{`
        
        Switch item`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PrivacyAreas
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Save the sitemap and open the basic UI on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080/basicui/app?sitemap=INSTAR`}</code>{`. You should now be able to see the `}<em parentName="p">{`Privacy Areas`}</em>{` switch:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/7a3d6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.304347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAa0lEQVQI143MOwoDIRAAUI9gCkFkfo6u4lpMYbO5/8UCCZZC3gGeSynFGPGLmUMI3vvXfxwRXVertTKziPAGAL/uhIjcGON53mY277tupZS1lpmpaj1QVSeSW+s5Zy2FNkScc/beAYAOEPEDkZIcoJwAONYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0162ffbeb66b4423f4b08a3e13e8088a/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 230w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 460w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 920w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/a96ba/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 990w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0162ffbeb66b4423f4b08a3e13e8088a/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 230w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 460w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 920w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/a66df/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 990w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0162ffbeb66b4423f4b08a3e13e8088a/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 230w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 460w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 920w", "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/7a3d6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 990w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0162ffbeb66b4423f4b08a3e13e8088a/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can use the `}<strong parentName="p">{`MapTransformation`}</strong>{` addon to change the text that is being displayed here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e1f54f96e68bef3770725454f0758d42/8de58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB9UlEQVQoz33RT2vacBwG8Jy7zq2a/vLPaKLLn1/+NJpoFTrXq0dv3gWPglAvXkRfQPsSusOo1+4ivgOPK6veK6b5LUptmTOCUciYLWUw2Of2wBeeB74YhFDXddM0BVGMkQQXZRiGIUiSJog4Q8eiTDKZ5DjuNTIMcwgABQBLUVjiRZKkKCGZOC0U0uk0VBQDwuPU0aePJ6ZlSbKsy/KxcXRaOMlkMh8EwVAUTRQwy7IUReE4LhwOn501Hp+eJpOJ4zgOQt9ubuz7e8d5Tuj77e3d3RghZNv2z8Xiy9UVZu5IkoTjeLvdDoJgu90GO9PpNPjL43zu+/7rQb/fxyCEsiyLoojjeKvVCoLg1x9L3/cdx9lsNp7nLZfL9Xrtuu5qtfK81WKxCIKg1+thmqbJsiwIAo7jnU7nP83zf5tVVYUQCoIAAKjX6w8PDwihHzvD4XDqulPXHY/HjuOMRiPbtmezGULI85bdbhfTd0RRpGm6WCzWarVsNpvP53O5nK7rmq7rKbPRaJRKJWtHVVVFgSkrA1UNS6XShmFIkhSJRJrN5vX1V4qiEokEACAUCr0/CL85AIPBoFqt0jTNsuzb/f13e3skoAATf/nz8+xKpXJxcc7zvCiKJEmSBBFl2UOCurz8XC6XOY7jeZ6mKIYk+VicYWO/AcitFOlkeCE7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1f54f96e68bef3770725454f0758d42/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 230w", "/en/static/e1f54f96e68bef3770725454f0758d42/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 460w", "/en/static/e1f54f96e68bef3770725454f0758d42/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 920w", "/en/static/e1f54f96e68bef3770725454f0758d42/8ce75/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e1f54f96e68bef3770725454f0758d42/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 230w", "/en/static/e1f54f96e68bef3770725454f0758d42/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 460w", "/en/static/e1f54f96e68bef3770725454f0758d42/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 920w", "/en/static/e1f54f96e68bef3770725454f0758d42/4fba2/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1f54f96e68bef3770725454f0758d42/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 230w", "/en/static/e1f54f96e68bef3770725454f0758d42/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 460w", "/en/static/e1f54f96e68bef3770725454f0758d42/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 920w", "/en/static/e1f54f96e68bef3770725454f0758d42/8de58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e1f54f96e68bef3770725454f0758d42/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We now have to define the transformation we want to perform. This can be done in:`}</p>
    <p><em parentName="p">{`transform/INSTAR.map`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`ON`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Private
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`OFF`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Not Private`}</code></pre></div>
    <p>{`To perform the transformation we can now link the map file to the switch in our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`items/itemtypes.item`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Switch   PrivacyAreas   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Type Switch [MAP(INSTAR.map):%s]"`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`switch`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it_group`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`   `}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/7e11a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "17.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAU0lEQVQI143LMQrAIAwFUK9gwXxsEDFJrUOcvP/dCu3Yxbe/ACDnzCczc0opxnhsC0QkItNn7132qGopBUAAyOxyn2PcqtpeZrbWcvdaa/sRkS8/lNwUgeJ1qAwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 230w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 460w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 920w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/b7e6f/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 1235w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 230w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 460w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 920w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/9c746/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 1235w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 230w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 460w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 920w", "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/7e11a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 1235w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/20ab6b17cf3effca70e8bc5a1e45ff3c/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      